import { purgeUserData } from "../account/account-helpers";
import {
  DENIED_FREE_CHAT_ERROR_MESSAGE,
  MAX_FREE_CHATS_ERROR_MESSAGE,
} from "../chats/chats-models";
import {
  BALANCE_TOO_LOW_ERROR,
  CHAT_ACCOUNT_NO_LONGER_EXISTS_ERROR_MESSAGE,
  CHAT_BLOCKED_COUNTRY_ERROR_MESSAGE,
  CHAT_BLOCKED_ERROR_MESSAGE,
  CHAT_CLOSED_ERROR_MESSAGE,
  CHAT_FROZEN_ERROR_MESSAGE_MODEL,
  CHAT_FROZEN_ERROR_MESSAGE_USER,
  CHAT_MESSAGE_INVALID_JSON_SINGLE_UNPAIRED_UTF16_SURROGATE_ERROR,
  CHAT_MESSAGE_INVALID_JSON_UNKNOWN_JSON_ENCODING_DECODING_ERROR,
  MESSAGE_ALREADY_PURCHASED_ERROR,
  NO_CREDITS_ERROR_MESSAGE,
} from "../chats/messenger/messenger-models";
import {
  AUTH_TOKEN_NAME,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_JSON_HEADERS,
  IS_PROD,
  URL_YII,
} from "../globals";
import { IDictionary } from "../models";
import { getCookie } from "./cookie";
import { sendAPIErrorMessage, sendWarningMessage } from "./slack";
import { defined } from "./variable-evaluation";

export function authToken() {
  return getCookie(AUTH_TOKEN_NAME);
}

export function globalHeaders() {
  return {
    "web-type": "react",
    "access-token": authToken() || "",
  };
}

export enum EResponseStatus {
  NoContent = 204,
  BadRequest = 400,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403, // right now API returns also unauthorized as forbidden
  NotFound = 404,
  Conflict = 409,
  Gone = 410,
  UnprocessableEntity = 422,
  Locked = 423, // this is used for banned users
  UnavailableForLegalReasons = 451,
  ServiceUnavailable = 503, // maintenance
}

export enum EFetchMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

// const DEFAULT_REQUEST_TIMEOUT = 20000; // ms

function parseResponse(response: Response, requestInit: RequestInit) {
  if (response.status === EResponseStatus.ServiceUnavailable) {
    window.location.href = `${URL_YII}/maintenance`;
  }

  if (response.status === EResponseStatus.NoContent) {
    // No Content
    return response;
    // return null;
  }

  const contentType = response.headers.get("content-type");
  if (!contentType || contentType.indexOf("application/json") === -1) {
    // handle non json response
    return response.text().then((parsedText) => {
      if (defined(parsedText) && parsedText.length > 0) {
        const title = parsedText.match("<title>(.*?)</title>")![1];

        const isNetDefender =
          parsedText.toLowerCase().indexOf("netdefender") !== -1;
        const isOtherAdultContentBlock =
          parsedText.toLowerCase().indexOf("adult content") !== -1;
        if (isNetDefender || isOtherAdultContentBlock) {
          sendWarningMessage(
            `\`${window.username}\` ${requestInit.method} ${response.url} \`${response.status}\` \`${contentType}\` ${title}`,
            IS_PROD ? "#web-logs-api-prod" : "#web-logs-api-dev",
            "block-bot",
            ":neutral_face:"
          );
        } else {
          const isFromCloudflare =
            parsedText.toLowerCase().indexOf("cloudflare") !== -1;
          if (isFromCloudflare || response.status >= 500) {
            const text = `\`${window.username}\` ${requestInit.method} ${response.url} \`${response.status}\` \`${contentType}\` ${title}`;
            sendWarningMessage(
              text,
              IS_PROD ? "#api-status" : "#web-logs-api-dev",
              "fire-bot",
              ":fire:"
            );
            if (IS_PROD && isFromCloudflare) {
              // send to API logs
              // fetch(`${API_ROOT}/site/502?text=${encodeURIComponent(text)}`);
              // try with hardcoded API_ROOT to dev to prevent from 50x also for this endpoint
              fetch(
                `https://api.41u4.com/site/502?text=${encodeURIComponent(text)}`
              ).then(
                (response) => {
                  response.text().then((parsedText) => {
                    sendWarningMessage(
                      `reporting cloudflare 50x success (${response.status})`
                    );
                  });
                },
                (error) =>
                  sendWarningMessage(
                    `reporting cloudflare 50x error (${response.status})`
                  )
              );
            }
          } else {
            sendWarningMessage(
              `
                \`${window.username}\` ${requestInit.method} ${response.url} \`${response.status}\` \`${contentType}\`
                \`\`\`${parsedText}\`\`\`
              `,
              IS_PROD ? "#web-logs-api-prod" : "#web-logs-api-dev",
              "what-bot",
              ":question:"
            );
          }
        }
      } else {
        sendWarningMessage(
          `
            \`${window.username}\` ${requestInit.method} ${response.url} \`${response.status}\` \`${contentType}\`
            \`\`\`${parsedText}\`\`\`
          `,
          IS_PROD
            ? response.status >= 500
              ? "#api-status"
              : "#web-logs-api-prod"
            : "#web-logs-api-dev",
          "what-bot",
          ":question:"
        );
      }

      // eslint-disable-next-line no-throw-literal
      throw {
        name: "Non JSON response",
        message: DEFAULT_ERROR_MESSAGE,
        status: 500,
        text: parsedText,
      };
    });
  } else {
    if (!response.ok) {
      if (
        // response.status === EResponseStatus.Unauthorized
        response.status === EResponseStatus.Forbidden ||
        response.status === EResponseStatus.Locked
      ) {
        purgeUserData();
      }
      return response.json().then((parsedResponse) => {
        if (response.status >= 500) {
          sendWarningMessage(
            `\`${window.username}\` ${requestInit.method} ${response.url} \`${response.status}\` ${parsedResponse.message}`,
            IS_PROD ? "#api-status" : "#web-logs-api-dev",
            "fire-bot",
            ":fire:"
          );
        } else {
          reportUnexpectedResponse(response, parsedResponse, requestInit);
        }
        throw parsedResponse;
      });
    }
    return response.json();
  }
}

const REPORT_IGNORE_LIST = [
  { errorMessage: "This link has expired, links only last 1 hour." },
  {
    errorMessageIncludes:
      "Your account has been temporarily suspended for violating the rules.",
  },
  { status: EResponseStatus.Forbidden, errorMessage: "Please sign in" },
  { status: EResponseStatus.Forbidden, errorMessage: "Forbidden" },
  { status: EResponseStatus.Locked },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/users/process",
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/users/sign",
    errorMessage: "Email is not a valid email address.",
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/users/send-verification-code",
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/users/verify-email",
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/users/resend-verify",
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/users/change-verify-email",
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/users/apply",
  },
  {
    method: EFetchMethod.GET,
    status: EResponseStatus.NotFound,
    urlIncludes: "/v1/users/images/public_id",
  },
  {
    method: EFetchMethod.DELETE,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/feed",
    errorMessage:
      "You cannot delete any more content until you cancel your subscriptions.",
  },
  {
    method: EFetchMethod.DELETE,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/feed",
    errorMessageIncludes:
      "You cannot delete any more content until your active subscriptions have ended.",
  },
  {
    method: EFetchMethod.GET,
    status: EResponseStatus.NotFound,
    urlIncludes: "/v1/chat?recipient_id=",
  },
  {
    method: EFetchMethod.GET,
    status: EResponseStatus.Gone,
    urlIncludes: "/v1/chat?recipient_id=",
  },
  {
    method: EFetchMethod.GET,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/chat?recipient_id=",
    errorMessage: "Chat not found",
  },
  {
    method: EFetchMethod.GET,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/chat?recipient_id=",
    errorMessage: CHAT_FROZEN_ERROR_MESSAGE_MODEL,
  },
  {
    method: EFetchMethod.GET,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/chat?recipient_id=",
    errorMessage: CHAT_FROZEN_ERROR_MESSAGE_USER,
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/chat",
    errorMessage: "Can not create chat with your own user.",
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/chat",
    errorMessage: MAX_FREE_CHATS_ERROR_MESSAGE,
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/chat",
    errorMessage: DENIED_FREE_CHAT_ERROR_MESSAGE,
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.PaymentRequired,
    urlIncludes: "/v1/chat",
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/chat",
    errorMessage: CHAT_FROZEN_ERROR_MESSAGE_MODEL,
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/chat",
    errorMessage: CHAT_FROZEN_ERROR_MESSAGE_USER,
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/chats/change-rate",
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/messages",
    errorMessage: "You can't send message to yourself",
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/messages",
    errorMessage: CHAT_CLOSED_ERROR_MESSAGE,
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/messages",
    errorMessage: CHAT_BLOCKED_ERROR_MESSAGE,
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/messages",
    errorMessage: CHAT_BLOCKED_COUNTRY_ERROR_MESSAGE,
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/messages",
    errorMessage: CHAT_ACCOUNT_NO_LONGER_EXISTS_ERROR_MESSAGE,
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/messages",
    errorMessage: CHAT_FROZEN_ERROR_MESSAGE_USER,
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/messages",
    errorMessage: CHAT_FROZEN_ERROR_MESSAGE_MODEL,
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/messages",
    errorMessage:
      CHAT_MESSAGE_INVALID_JSON_UNKNOWN_JSON_ENCODING_DECODING_ERROR,
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/messages",
    errorMessage:
      CHAT_MESSAGE_INVALID_JSON_SINGLE_UNPAIRED_UTF16_SURROGATE_ERROR,
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.PaymentRequired,
    urlIncludes: "/v1/messages",
    errorMessage: NO_CREDITS_ERROR_MESSAGE,
  },
  {
    method: EFetchMethod.PATCH,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/messages",
    errorMessage: MESSAGE_ALREADY_PURCHASED_ERROR,
  },
  {
    method: EFetchMethod.PATCH,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/messages",
    errorMessage: BALANCE_TOO_LOW_ERROR,
  },
  {
    method: EFetchMethod.GET,
    status: EResponseStatus.Gone,
    urlIncludes: "/v1/users/",
    errorMessage: "Sorry, this account no longer exists.",
  },
  {
    method: EFetchMethod.GET,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/users/",
    errorMessage: "User Cannot Be Found Or Does Not Exist",
  },
  {
    method: EFetchMethod.PATCH,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/users/",
    errorMessage: "Usernames can only contain letters, numbers and hyphens.",
  },
  {
    method: EFetchMethod.PATCH,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/users/",
    errorMessage: "Usernames Cannot Be More Than 30 Characters",
  },
  {
    method: EFetchMethod.PATCH,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/users/",
    errorMessage: "This Username Has Already Been Taken",
  },
  {
    method: EFetchMethod.PATCH,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/users/",
    errorMessage:
      "Sorry, you cannot mention exclusive content in your title/bio",
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/payment",
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/users/apply",
    errorMessage: "Sorry. Your application was rejected. You can't apply again",
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v2/account/payout",
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.UnprocessableEntity,
    urlIncludes: "/v2/account/payout",
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.UnavailableForLegalReasons,
    urlIncludes: "/v2/account/payout",
  },
  {
    method: EFetchMethod.PATCH,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/account/defaults",
  },
  {
    method: EFetchMethod.PATCH,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v2/account/defaults",
  },
  {
    method: EFetchMethod.DELETE,
    status: EResponseStatus.Conflict,
    urlIncludes: "/v1/cc",
  },
  {
    method: EFetchMethod.POST,
    status: EResponseStatus.BadRequest,
    urlIncludes: "/v1/broadcast/send",
  },
];
function reportUnexpectedResponse(
  response: Response,
  parsedResponse: any,
  requestInit: RequestInit
) {
  const matchedIgnoreItem = REPORT_IGNORE_LIST.find(
    (ignoreItem) =>
      (!defined(ignoreItem.method) ||
        requestInit.method === ignoreItem.method) &&
      (!defined(ignoreItem.status) || response.status === ignoreItem.status) &&
      (!defined(ignoreItem.urlIncludes) ||
        response.url.includes(ignoreItem.urlIncludes!)) &&
      (!defined(ignoreItem.errorMessage) ||
        parsedResponse.message === ignoreItem.errorMessage) &&
      (!defined(ignoreItem.errorMessageIncludes) ||
        parsedResponse.message.includes(ignoreItem.errorMessageIncludes))
  );
  if (!matchedIgnoreItem) {
    const channel = IS_PROD && response.status === 500 ? "#web" : undefined;
    sendAPIErrorMessage(
      `\`${window.username}\` ${requestInit.method} ${
        response.url
      } responded with \`${response.status}\`. \`\`\`${JSON.stringify(
        parsedResponse
      )}\`\`\` \`\`\`${requestInit.body}\`\`\``,
      channel
    );
  }
}

// export function checkIsJson(response) {
//   try {
//     const data = JSON.parse(response.text);
//     return data.json();
//   } catch (err) {
//     return response.text();
//   }
// }

/** Utility method for sending a GET request to the specified URL */
export function getRequest<T>(
  url: string,
  options?: {
    extraHeaders?: IDictionary<string>;
    abortController?: AbortController;
    // timeout?: number;
    // useContinuousDurationReporting?: boolean;
  }
): Promise<T> {
  // const ts = Date.now();
  if (!options) {
    options = {};
  }
  if (!options.abortController) {
    options.abortController = new AbortController();
  }
  // if (!options.timeout) {
  //   options.timeout = DEFAULT_REQUEST_TIMEOUT;
  // }
  const requestInit: RequestInit = {
    method: EFetchMethod.GET,
    headers: {
      ...globalHeaders(),
      ...DEFAULT_JSON_HEADERS,
      ...options.extraHeaders,
    },
    credentials: "same-origin",
    signal: options.abortController.signal,
  };
  // const timeoutId = setTimeout(() => {
  //   sendWarningMessage(
  //     `\`${window.username}\` \`${
  //       requestInit.method
  //     }\` \`${url}\` taking over \`${
  //       options!.timeout! / 1000
  //     }\` seconds would be aborted...`,
  //     isProd ? "#web-logs-api-prod" : "#web-logs-api-dev",
  //     "timeout-bot",
  //     ":time-zone-fyi:"
  //   );
  //   // options!.abortController!.abort();
  // }, options.timeout);
  // let intervalId: any;
  // if (options?.useContinuousDurationReporting) {
  //   intervalId = setInterval(() => {
  //     const responseTime = Date.now() - ts;
  //     if (responseTime > DEFAULT_REQUEST_TIMEOUT) {
  //       sendWarningMessage(
  //         `\`${window.username}\` \`${
  //           requestInit.method
  //         }\` \`${url}\` taking \`${responseTime / 1000}\` seconds so far...`,
  //         isProd ? "#web-logs-api-prod" : "#web-logs-api-dev",
  //         "timeout-bot",
  //         ":time-zone-fyi:"
  //       );
  //     }
  //   }, 5000);
  // }
  return fetch(url, requestInit).then((response) =>
    parseResponse(response, requestInit)
  );
  // .finally(() => {
  //   clearTimeout(timeoutId);
  //   clearInterval(intervalId);

  //   const responseTime = Date.now() - ts;
  //   if (responseTime > DEFAULT_REQUEST_TIMEOUT) {
  //     sendWarningMessage(
  //       `\`${window.username}\` \`${
  //         requestInit.method
  //       }\` \`${url}\` response took \`${responseTime / 1000} seconds\`...`,
  //       isProd ? "#web-logs-api-prod" : "#web-logs-api-dev",
  //       "timeout-bot",
  //       ":time-zone-fyi:"
  //     );
  //   }
  // });
}

/** Utility method for sending a PUT request to the specified URL */
export function putRequest<T>(
  url: string,
  body: IDictionary<any>,
  options?: {
    extraHeaders?: IDictionary<string>;
    abortController?: AbortController;
    // timeout?: number;
    // useContinuousDurationReporting?: boolean;
  }
): Promise<T> {
  // const ts = Date.now();
  if (!options) {
    options = {};
  }
  if (!options.abortController) {
    options.abortController = new AbortController();
  }
  // if (!options.timeout) {
  //   options.timeout = DEFAULT_REQUEST_TIMEOUT;
  // }
  const requestInit: RequestInit = {
    method: EFetchMethod.PUT,
    body: JSON.stringify(body),
    headers: {
      ...globalHeaders(),
      ...DEFAULT_JSON_HEADERS,
      "cache-control": "no-cache", // Make sure brain dead iOS safari won't cache this https://stackoverflow.com/questions/12506897/is-safari-on-ios-6-caching-ajax-results
      ...options.extraHeaders,
    },
    credentials: "same-origin",
    signal: options.abortController.signal,
  };
  // const timeoutId = setTimeout(() => {
  //   sendWarningMessage(
  //     `\`${window.username}\` \`${
  //       requestInit.method
  //     }\` \`${url}\` taking over \`${
  //       options!.timeout! / 1000
  //     }\` seconds would be aborted...`,
  //     isProd ? "#web-logs-api-prod" : "#web-logs-api-dev",
  //     "timeout-bot",
  //     ":time-zone-fyi:"
  //   );
  //   // options!.abortController!.abort();
  // }, options.timeout);
  // let intervalId: any;
  // if (options?.useContinuousDurationReporting) {
  //   intervalId = setInterval(() => {
  //     const responseTime = Date.now() - ts;
  //     if (responseTime > DEFAULT_REQUEST_TIMEOUT) {
  //       sendWarningMessage(
  //         `\`${window.username}\` \`${
  //           requestInit.method
  //         }\` \`${url}\` taking \`${responseTime / 1000}\` seconds so far...`,
  //         isProd ? "#web-logs-api-prod" : "#web-logs-api-dev",
  //         "timeout-bot",
  //         ":time-zone-fyi:"
  //       );
  //     }
  //   }, 5000);
  // }
  return fetch(url, requestInit).then((response) =>
    parseResponse(response, requestInit)
  );
  // .finally(() => {
  //   clearTimeout(timeoutId);
  //   clearInterval(intervalId);

  //   const responseTime = Date.now() - ts;
  //   if (responseTime > DEFAULT_REQUEST_TIMEOUT) {
  //     sendWarningMessage(
  //       `\`${window.username}\` \`${
  //         requestInit.method
  //       }\` \`${url}\` response took \`${responseTime / 1000} seconds\`...`,
  //       isProd ? "#web-logs-api-prod" : "#web-logs-api-dev",
  //       "timeout-bot",
  //       ":time-zone-fyi:"
  //     );
  //   }
  // });
}

/** Utility method for sending a PATCH request to the specified URL */
export function patchRequest<T>(
  url: string,
  body: IDictionary<any>,
  options?: {
    extraHeaders?: IDictionary<string>;
    abortController?: AbortController;
    // timeout?: number;
    // useContinuousDurationReporting?: boolean;
  }
): Promise<T> {
  // const ts = Date.now();
  if (!options) {
    options = {};
  }
  if (!options.abortController) {
    options.abortController = new AbortController();
  }
  // if (!options.timeout) {
  //   options.timeout = DEFAULT_REQUEST_TIMEOUT;
  // }
  const requestInit: RequestInit = {
    method: EFetchMethod.PATCH,
    body: JSON.stringify(body),
    headers: {
      ...globalHeaders(),
      ...DEFAULT_JSON_HEADERS,
      "cache-control": "no-cache", // Make sure brain dead iOS safari won't cache this https://stackoverflow.com/questions/12506897/is-safari-on-ios-6-caching-ajax-results
      ...options.extraHeaders,
    },
    credentials: "same-origin",
    signal: options.abortController.signal,
  };
  // const timeoutId = setTimeout(() => {
  //   sendWarningMessage(
  //     `\`${window.username}\` \`${
  //       requestInit.method
  //     }\` \`${url}\` taking over \`${
  //       options!.timeout! / 1000
  //     }\` seconds would be aborted...`,
  //     isProd ? "#web-logs-api-prod" : "#web-logs-api-dev",
  //     "timeout-bot",
  //     ":time-zone-fyi:"
  //   );
  //   // options!.abortController!.abort();
  // }, options.timeout);
  // let intervalId: any;
  // if (options?.useContinuousDurationReporting) {
  //   intervalId = setInterval(() => {
  //     const responseTime = Date.now() - ts;
  //     if (responseTime > DEFAULT_REQUEST_TIMEOUT) {
  //       sendWarningMessage(
  //         `\`${window.username}\` \`${
  //           requestInit.method
  //         }\` \`${url}\` taking \`${responseTime / 1000}\` seconds so far...`,
  //         isProd ? "#web-logs-api-prod" : "#web-logs-api-dev",
  //         "timeout-bot",
  //         ":time-zone-fyi:"
  //       );
  //     }
  //   }, 5000);
  // }
  return fetch(url, requestInit).then((response) =>
    parseResponse(response, requestInit)
  );
  // .finally(() => {
  //   clearTimeout(timeoutId);
  //   clearInterval(intervalId);

  //   const responseTime = Date.now() - ts;
  //   if (responseTime > DEFAULT_REQUEST_TIMEOUT) {
  //     sendWarningMessage(
  //       `\`${window.username}\` \`${
  //         requestInit.method
  //       }\` \`${url}\` response took \`${responseTime / 1000} seconds\`...`,
  //       isProd ? "#web-logs-api-prod" : "#web-logs-api-dev",
  //       "timeout-bot",
  //       ":time-zone-fyi:"
  //     );
  //   }
  // });
}

/** Utility method for sending a DELETE request to the specified URL */
export function deleteRequest<T>(
  url: string,
  options?: {
    extraHeaders?: IDictionary<string>;
    abortController?: AbortController;
    // timeout?: number;
    // useContinuousDurationReporting?: boolean;
  }
): Promise<T> {
  // const ts = Date.now();
  if (!options) {
    options = {};
  }
  if (!options.abortController) {
    options.abortController = new AbortController();
  }
  // if (!options.timeout) {
  //   options.timeout = DEFAULT_REQUEST_TIMEOUT;
  // }
  const requestInit: RequestInit = {
    method: EFetchMethod.DELETE,
    headers: {
      ...globalHeaders(),
      ...DEFAULT_JSON_HEADERS,
      "cache-control": "no-cache", // Make sure brain dead iOS safari won't cache this https://stackoverflow.com/questions/12506897/is-safari-on-ios-6-caching-ajax-results
      ...options.extraHeaders,
    },
    credentials: "same-origin",
    signal: options.abortController.signal,
  };
  // const timeoutId = setTimeout(() => {
  //   sendWarningMessage(
  //     `\`${window.username}\` \`${
  //       requestInit.method
  //     }\` \`${url}\` taking over \`${
  //       options!.timeout! / 1000
  //     }\` seconds would be aborted...`,
  //     isProd ? "#web-logs-api-prod" : "#web-logs-api-dev",
  //     "timeout-bot",
  //     ":time-zone-fyi:"
  //   );
  //   // options!.abortController!.abort();
  // }, options.timeout);
  // let intervalId: any;
  // if (options?.useContinuousDurationReporting) {
  //   intervalId = setInterval(() => {
  //     const responseTime = Date.now() - ts;
  //     if (responseTime > DEFAULT_REQUEST_TIMEOUT) {
  //       sendWarningMessage(
  //         `\`${window.username}\` \`${
  //           requestInit.method
  //         }\` \`${url}\` taking \`${responseTime / 1000}\` seconds so far...`,
  //         isProd ? "#web-logs-api-prod" : "#web-logs-api-dev",
  //         "timeout-bot",
  //         ":time-zone-fyi:"
  //       );
  //     }
  //   }, 5000);
  // }
  return fetch(url, requestInit).then((response) =>
    parseResponse(response, requestInit)
  );
  // .finally(() => {
  //   clearTimeout(timeoutId);
  //   clearInterval(intervalId);

  //   const responseTime = Date.now() - ts;
  //   if (responseTime > DEFAULT_REQUEST_TIMEOUT) {
  //     sendWarningMessage(
  //       `\`${window.username}\` \`${
  //         requestInit.method
  //       }\` \`${url}\` response took \`${responseTime / 1000} seconds\`...`,
  //       isProd ? "#web-logs-api-prod" : "#web-logs-api-dev",
  //       "timeout-bot",
  //       ":time-zone-fyi:"
  //     );
  //   }
  // });
}

/** Utility method for sending a POST request to the specified URL */
export function postRequest<T>(
  url: string,
  body: IDictionary<any>,
  options?: {
    extraHeaders?: IDictionary<string>;
    abortController?: AbortController;
    // timeout?: number;
    // useContinuousDurationReporting?: boolean;
  }
): Promise<T> {
  // const ts = Date.now();
  if (!options) {
    options = {};
  }
  if (!options.abortController) {
    options.abortController = new AbortController();
  }
  // if (!options.timeout) {
  //   options.timeout = DEFAULT_REQUEST_TIMEOUT;
  // }
  const requestInit: RequestInit = {
    method: EFetchMethod.POST,
    body: JSON.stringify(body),
    headers: {
      ...globalHeaders(),
      ...DEFAULT_JSON_HEADERS,
      "cache-control": "no-cache", // Make sure brain dead iOS safari won't cache this https://stackoverflow.com/questions/12506897/is-safari-on-ios-6-caching-ajax-results
      ...options.extraHeaders,
    },
    credentials: "same-origin",
    signal: options.abortController.signal,
  };
  // const timeoutId = setTimeout(() => {
  //   sendWarningMessage(
  //     `\`${window.username}\` \`${
  //       requestInit.method
  //     }\` \`${url}\` taking over \`${
  //       options!.timeout! / 1000
  //     }\` seconds would be aborted...`,
  //     isProd ? "#web-logs-api-prod" : "#web-logs-api-dev",
  //     "timeout-bot",
  //     ":time-zone-fyi:"
  //   );
  //   // options!.abortController!.abort();
  // }, options.timeout);
  // let intervalId: any;
  // if (options?.useContinuousDurationReporting) {
  //   intervalId = setInterval(() => {
  //     const responseTime = Date.now() - ts;
  //     if (responseTime > DEFAULT_REQUEST_TIMEOUT) {
  //       sendWarningMessage(
  //         `\`${window.username}\` \`${
  //           requestInit.method
  //         }\` \`${url}\` taking \`${responseTime / 1000}\` seconds so far...`,
  //         isProd ? "#web-logs-api-prod" : "#web-logs-api-dev",
  //         "timeout-bot",
  //         ":time-zone-fyi:"
  //       );
  //     }
  //   }, 5000);
  // }
  return fetch(url, requestInit).then((response) =>
    parseResponse(response, requestInit)
  );
  // .finally(() => {
  //   clearTimeout(timeoutId);
  //   clearInterval(intervalId);

  //   const responseTime = Date.now() - ts;
  //   if (responseTime > DEFAULT_REQUEST_TIMEOUT) {
  //     sendWarningMessage(
  //       `\`${window.username}\` \`${
  //         requestInit.method
  //       }\` \`${url}\` response took \`${responseTime / 1000} seconds\`...`,
  //       isProd ? "#web-logs-api-prod" : "#web-logs-api-dev",
  //       "timeout-bot",
  //       ":time-zone-fyi:"
  //     );
  //   }
  // });
}

/** Utility method for sending an unstringified POST request to the specified URL */
export function postFileRequest<T>(
  url: string,
  body: any,
  options?: {
    extraHeaders?: IDictionary<string>;
    abortController?: AbortController;
    // timeout?: number;
    // useContinuousDurationReporting?: boolean;
  }
): Promise<T> {
  // const ts = Date.now();
  if (!options) {
    options = {};
  }
  if (!options.abortController) {
    options.abortController = new AbortController();
  }
  // if (!options.timeout) {
  //   options.timeout = DEFAULT_REQUEST_TIMEOUT;
  // }
  const requestInit: RequestInit = {
    method: EFetchMethod.POST,
    body,
    headers: {
      ...globalHeaders(),
      "cache-control": "no-cache", // Make sure brain dead iOS safari won't cache this https://stackoverflow.com/questions/12506897/is-safari-on-ios-6-caching-ajax-results
      ...options.extraHeaders,
    },
    credentials: "same-origin",
    signal: options.abortController.signal,
  };
  // const timeoutId = setTimeout(() => {
  //   sendWarningMessage(
  //     `\`${window.username}\` \`${
  //       requestInit.method
  //     }\` \`${url}\` taking over \`${
  //       options!.timeout! / 1000
  //     }\` seconds would be aborted...`,
  //     isProd ? "#web-logs-api-prod" : "#web-logs-api-dev",
  //     "timeout-bot",
  //     ":time-zone-fyi:"
  //   );
  //   // options!.abortController!.abort();
  // }, options.timeout);
  // let intervalId: any;
  // if (options?.useContinuousDurationReporting) {
  //   intervalId = setInterval(() => {
  //     const responseTime = Date.now() - ts;
  //     if (responseTime > DEFAULT_REQUEST_TIMEOUT) {
  //       sendWarningMessage(
  //         `\`${window.username}\` \`${
  //           requestInit.method
  //         }\` \`${url}\` taking \`${responseTime / 1000}\` seconds so far...`,
  //         isProd ? "#web-logs-api-prod" : "#web-logs-api-dev",
  //         "timeout-bot",
  //         ":time-zone-fyi:"
  //       );
  //     }
  //   }, 5000);
  // }
  return fetch(url, requestInit).then((response) =>
    parseResponse(response, requestInit)
  );
  // .finally(() => {
  //   clearTimeout(timeoutId);
  //   clearInterval(intervalId);

  //   const responseTime = Date.now() - ts;
  //   if (responseTime > DEFAULT_REQUEST_TIMEOUT) {
  //     sendWarningMessage(
  //       `\`${window.username}\` \`${
  //         requestInit.method
  //       }\` \`${url}\` response took \`${responseTime / 1000} seconds\`...`,
  //       isProd ? "#web-logs-api-prod" : "#web-logs-api-dev",
  //       "timeout-bot",
  //       ":time-zone-fyi:"
  //     );
  //   }
  // });
}
